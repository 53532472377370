import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import '~styles/flickity.css'
import canUseDOM from '~utils/canUseDOM'
import { LongRarr } from '~components/Svg'
import RichText from '~components/RichText'
import Image from '~components/Image'

const ProductSlider = ({ className, slides }) => {

	const [init, setInit] = useState()
	const [integer, setInteger] = useState(0)

	const flkty = useRef()
	const flickityEl = useRef()

	useEffect(() => {
		if (canUseDOM && init){
			const Flickity = require('flickity')
			flkty.current = new Flickity(flickityEl.current, flickityOptions)
		}
		setInit(true)
	}, [init])

	useEffect(() => {
		if (init) {
			flkty?.current?.on('change', () => {
				setInteger(flkty?.current?.selectedIndex)
			} )
		}
	}, [init])

	const flickityOptions = {
		prevNextButtons: false,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: true,
		cellAlign: 'left',
		freeScroll: false,
		contain: true,
		fade: true
	}

	const next = () => {
		flkty?.current?.next()
	}
	const prev = () => {
		flkty?.current?.previous()
	}

	return (
		<Wrap className={className}>
			<Flickity ref={flickityEl}>
				{slides?.map(s => (
					<Slide key={s?.asset?._id} image={s} aspectRatio={1}/>
				))}
			</Flickity>	
			<Buttons>
				<Left onClick={() => prev()}><LeftArrow /></Left>
				<Title>
					<RichText content={slides && slides[integer]?.caption} /> 
				</Title>
				<Right onClick={() => next()}><RightArrow /></Right>
			</Buttons>
		</Wrap>
	)
}

const Wrap = styled.div`
	
`
const Flickity = styled.div`
	width: 100%;
`
const Slide = styled(Image)`
	width: 100%;
`
const Buttons = styled.div`
	display: flex;
	align-items: center;
	height: 71px;
	border-top: 1px solid;
	border-bottom: 1px solid;
`
const Left = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 100%;
`
const Right = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 100%;
`
const Title = styled.h6`
	flex: 1;
	text-align: center;
`
const LeftArrow = styled(LongRarr)`
	width: 16px;
	transform: rotate(180deg);
	display: block;
	position: relative;
	left: -0.1em;
`
const RightArrow = styled(LongRarr)`
	width: 16px;
	display: block;
`

ProductSlider.propTypes = {
	className: PropTypes.string,
	slides: PropTypes.array,
}

export default ProductSlider